html {
  height: 100vh;
}

body {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
}

* {
  min-height: 0;
  min-width: 0;
}

.calculator {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .slick-dots li {
    margin: 0;
  }
}
